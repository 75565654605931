import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
import { convertCurrencySummaryLocation, datePicker } from "../helpers";

@inject("AppStore")
@observer
class PaymentStep extends React.Component {
  store = this.props.AppStore;
  dates = datePicker();

  render() {
    const { store } = this.props;

    return (
      <div className="step-payment-content">
        <div className="left">
          <h2>Enter Your Detail</h2>

          <form id="payment-form" onSubmit={store.onPaymentSubmit}>
            <div
              className="form"
              style={{ borderBottom: "1px solid", paddingBottom: "1rem" }}
            >
              <div className="form-item">
                <label>First Name</label>
                <input
                  required
                  type="text"
                  name="firstname"
                  value={store.detailsForm.firstname}
                  onChange={(e) =>
                    (store.detailsForm.firstname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Last Name</label>
                <input
                  required
                  type="text"
                  name="lastname"
                  value={store.detailsForm.lastname}
                  onChange={(e) =>
                    (store.detailsForm.lastname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Email Address</label>
                <input
                  required
                  type="email"
                  name="email"
                  value={store.detailsForm.email}
                  onChange={(e) => (store.detailsForm.email = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Phone Number</label>
                <input
                  required
                  type="text"
                  name="phone"
                  value={store.detailsForm.phone}
                  onChange={(e) => (store.detailsForm.phone = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Where are you boating?</label>
                <input
                  type="text"
                  name="boating"
                  value={store.detailsForm.boating}
                  onChange={(e) => (store.detailsForm.boating = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Preffered Delivery Date</label>
                <select
                  value={store.detailsForm.delivery}
                  onChange={(e) =>
                    (store.detailsForm.delivery = e.target.value)
                  }
                >
                  <option></option>
                  {this.dates.map((d, i) => (
                    <option value={d.value} key={i} disabled={d.disabled}>
                      {d.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <h2>Card</h2>

            {store.page.error && (
              <div className="error">{store.page.error}</div>
            )}

            <div className="form">
              <div className="form-item">
                <label>Name on Card</label>
                <input
                  required
                  type="text"
                  name="name"
                  value={store.paymentForm.name}
                  onChange={(e) => (store.paymentForm.name = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Credit Card Number</label>
                <input
                  required
                  type="text"
                  name="lastname"
                  value={store.paymentForm.cardnumber}
                  onChange={(e) =>
                    (store.paymentForm.cardnumber = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Exp. Month</label>
                <input
                  required
                  type="text"
                  name="exp_month"
                  value={store.paymentForm.exp_month}
                  onChange={(e) =>
                    (store.paymentForm.exp_month = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>Exp. Year</label>
                <input
                  required
                  type="text"
                  name="exp_year"
                  value={store.paymentForm.exp_year}
                  onChange={(e) =>
                    (store.paymentForm.exp_year = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>CVV</label>
                <input
                  required
                  type="text"
                  name="cvv"
                  value={store.paymentForm.cvv}
                  onChange={(e) => (store.paymentForm.cvv = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Billing zip code</label>
                <input
                  type="text"
                  name="zipcode"
                  value={store.paymentForm.zipcode}
                  onChange={(e) => (store.paymentForm.zipcode = e.target.value)}
                />
              </div>
            </div>

            <div style={{ textAlign: "right", marginTop: 50 }}>
              <div
                className="modelSelector__cleanbutton"
                style={{ display: "inline-block" }}
              >
                <button>
                  RESERVE NOW (
                  {convertCurrencySummaryLocation(
                    store.data.due_today_price,
                    this.store.location,
                    this.store.selectedCurrency,
                  )}
                  )
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="options-wrapper">
          <img
            className="image"
            src={
              store.data.picture
                ? `${appConfig.api}${store.data.picture.url}`
                : "https://via.placeholder.com/150"
            }
            alt=""
          />

          <div dangerouslySetInnerHTML={{ __html: store.data.name }}></div>
          <div className="upgrades-wrapper">
            <h3>UPGRADES & OPTIONS</h3>
            <div className="upgrades">
              {Object.keys(store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title != "null" && (
                            <div
                              className="upgradeoption-option-group-title"
                              style={{ marginLeft: 35 }}
                            >
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            store.summary.upgradeOptionsDisplay[step][
                            option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div
                                className="upgradeoption-option-group"
                                style={{
                                  marginLeft:
                                    option_group_title != "null" ? 45 : 35,
                                }}
                              >
                                {option_group}
                              </div>

                              {store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                  style={{
                                    marginLeft:
                                      option_group_title != "null" ? 55 : 45,
                                  }}
                                >
                                  <span
                                    className="text"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div className="upgradeoption-option">
                                      {option.title}
                                    </div>
                                  </span>

                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: store.data.color,
                                      fontWeight: "bold",
                                      flex: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      this.store.location,
                                      this.store.selectedCurrency,
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">PURCHASE PRICE</div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  store.summary.purchase_price,
                  this.store.location,
                  this.store.selectedCurrency,
                )}
              </div>
            </div>
            <div className="purchase-price duetoday">
              <div className="purchase-price-text">RESERVATION FEE</div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  store.data.due_today_price,
                  this.store.location,
                  this.store.selectedCurrency,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentStep;
