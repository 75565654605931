import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
import { convertCurrencySummaryLocation } from "../helpers";
import { Translation } from "../Translation";
import { FiEdit2 } from "react-icons/fi";

@inject("AppStore")
@observer
class SummaryStep extends React.Component {
  store = this.props.AppStore;

  render() {
    return (
      <div className="step-summary-content">
        <div className="left">
          <div className="heading">
            <h1>
              <Translation
                lang={this.store.page.lang}
                t="summary_title"
                params={{ model: this.store.data.name }}
              />
            </h1>
          </div>

          <div
            className="image"
            style={{
              backgroundImage:
                this.store.data.picture &&
                `url(${appConfig.api}${this.store.data.picture.url})`,
            }}
          ></div>
        </div>
        <div className="options-wrapper">
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: this.store.data.name }}
          ></div>

          {/* <div className="standards-wrapper">
            <h3>
              <Translation lang={this.store.page.lang} t="summary_standards" />
            </h3>
            <div className="standards">
              <div className="standards-left">
                <pre>{this.store.data[this.store.langify("standards")]}</pre>
              </div>
              <div className="standards-right">
                <pre>{this.store.data[this.store.langify("standards_right")]}</pre>
              </div>
            </div>
          </div> */}
          <div className="upgrades-wrapper">
            <div className="option-group-title">
              <div
                className="selectedModel__subtitle--top uc"
                style={{ borderColor: `${this.store.data.color}` }}
              >
                ORDER
              </div>
              <div className="selectedModel__subtitle--bottom">SUMMARY</div>
            </div>
            <div className="upgrades">
              {Object.keys(this.store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        this.store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title != "null" && (
                            <div
                              className="upgradeoption-option-group-title"
                              style={{ marginLeft: 35 }}
                            >
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            this.store.summary.upgradeOptionsDisplay[step][
                            option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div
                                className="upgradeoption-option-group"
                                style={{
                                  marginLeft:
                                    option_group_title != "null" ? 45 : 35,
                                }}
                              >
                                {option_group}
                              </div>

                              {this.store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                  style={{
                                    marginLeft:
                                      option_group_title != "null" ? 55 : 45,
                                  }}
                                >
                                  <span
                                    className="text"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div
                                      className="upgradeoption-option"
                                      dangerouslySetInnerHTML={{
                                        __html: option.title,
                                      }}
                                    ></div>
                                  </span>
                                  <span
                                    className="upgradeoption-edit"
                                    style={{
                                      cursor: "pointer",
                                      color: this.store.data.color,
                                      marginRight: 10,
                                      fontWeight: "bold",
                                      padding: 5,
                                    }}
                                    onClick={() => {
                                      this.store.goToOption(option);
                                    }}
                                  >
                                    <FiEdit2 />
                                  </span>
                                  {!this.store.location.hide_price && (
                                    <span
                                      className="price animate__animated animate__fadeIn"
                                      style={{
                                        color: this.store.data.color,
                                        fontWeight: "bold",
                                        flex: 1,
                                        textAlign: "right",
                                      }}
                                    >
                                      {convertCurrencySummaryLocation(
                                        option.price,
                                        this.store.location,
                                        this.store.selectedCurrency
                                      )}
                                    </span>
                                  )}
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          {!this.store.location.hide_price && (
            <div className="purchase-wrapper">
              <div className="purchase-price">
                <div className="purchase-price-text">
                  <Translation
                    lang={this.store.page.lang}
                    t="footer_purchase_price"
                  />{" "}
                  *
                </div>
                <div className="purchase-price-amount">
                  {convertCurrencySummaryLocation(
                    this.store.summary.purchase_price,
                    this.store.location,
                    this.store.selectedCurrency,
                  )}
                </div>
              </div>
              <div
                className="purchase-price reservation"
                style={{ display: "none" }}
              >
                <div className="purchase-price-text">Reservation Fee</div>
                <div className="purchase-price-amount">
                  {convertCurrencySummaryLocation(
                    this.store.summary.due_today_price,
                    this.store.location,
                    this.store.selectedCurrency,
                    true
                  )}
                </div>
              </div>
              <div style={{ marginTop: 35, fontSize: 12 }}>
                * Purchase Price including selected options for delivery ex-factory  - transportation and VAT excluded
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SummaryStep;
