import React from "react";
import { inject, observer } from "mobx-react";
import { Step } from "./Components/Step";
import ImageStep from "./Components/ImageStep";
import GridStep from "./Components/GridStep";

import CountUp from "react-countup";
import SummaryStep from "./Components/SummaryStep";
import PdfStep from "./Components/PdfStep";
import CompleteStep from "./Components/CompleteStep";
import PaymentStep from "./Components/PaymentStep";
import "./App.css";
import { Translation } from "./Translation";
import { appConfig } from "./config";
import { Loading } from "./Components/Loading";
import SmoothScrolling from "./Helpers/smootScrolling";
import { ImWhatsapp, ImPhone } from "react-icons/im";
import { AiOutlineMail } from "react-icons/ai";
import { SpecPopup } from "./Components/SpecPopup";
import DependencyPopup from "./Components/DependencyPopup";
import DependantPopup from "./Components/DependantPopup";

@inject("AppStore")
@observer
class App extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    const { slug } = this.props.match.params;

    this.store.getLocation("default");
    this.store.loadConfig();

    // get path
    this.store.init(slug);
  }

  render() {
    const { page, data } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a href={`${appConfig.locale.url}`}>
              <img
                src={appConfig.locale.api + this.store.location.logo.url}
                className="logo"
                alt=""
              />
            </a>
          </div>
          <div
            className="steps"
            style={page.hideSteps ? { display: "none" } : null}
          >
            {!this.store.page.completed && (
              <React.Fragment>
                {data.steps.map((step, i) => (
                  <Step
                    key={i}
                    order={i + 1}
                    visited={i <= page.stepIndex}
                    selected={i === page.stepIndex}
                    onSelected={() => {
                      this.store.onStepSelected(step, i);
                    }}
                    title={step[this.store.langify("title")]}
                    color={this.store.data.color}
                  />
                ))}
                <Step
                  order={page.summaryStep}
                  visited={page.summaryStep <= page.stepIndex}
                  selected={page.summaryStep <= page.stepIndex}
                  onSelected={() => {
                    this.store.onStepSelected({}, page.summaryStep);
                  }}
                  title={this.store.page.lang === "en" ? "SUMMARY" : "ÖZET"}
                />
              </React.Fragment>
            )}
          </div>
          <div style={{ marginRight: 40, marginTop: 24, display: 'flex', alignItems: 'center' }}>
            <strong style={{ fontSize: 12, marginRight: 5 }}>SELECT CURRENCY:</strong>
            <select style={{ width: 80, padding: '5px 10px' }} onChange={e => this.store.changeCurrency(e.target.value)}>
              <option value="sek" selected={this.store.selectedCurrency == 'sek'}>SEK</option>
              <option value="eur" selected={this.store.selectedCurrency == 'eur'}>EUR</option>
            </select>
          </div>
        </div>
        <div className="content">
          {page.completeStep !== page.stepIndex && (
            <React.Fragment>
              {!page.hideSteps &&
                data.steps.map((step, i) => (
                  <div
                    key={i}
                    className={`step-content-inner visible-mobile ${i == page.stepIndex &&
                      "flex animate__animated animate__fadeIn"
                      }`}
                    data-title={step.title}
                  >
                    <div className="title">
                      <span>{step[this.store.langify("title")]}</span>
                    </div>
                    {step.type === "Image" && (
                      <ImageStep store={this.store} step={step} data={data} />
                    )}
                    {step.type === "Grid" && (
                      <GridStep store={this.store} step={step} />
                    )}
                  </div>
                ))}
              <div
                id="step-summary"
                className={`step-content-inner visible-mobile ${page.summaryStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="SUMMARY"
              >
                <div className="title" id="summary">
                  {this.store.page.lang === "en" ? "SUMMARY" : "ÖZET"}
                </div>
                <SummaryStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${page.pdfStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="Pdf"
              >
                <div className="title" id="pdf">
                  PDF
                </div>
                <PdfStep store={this.store} />
              </div>

              <div
                className={`step-content-inner ${page.paymentStep === page.stepIndex &&
                  "flex animate__animated animate__fadeIn"
                  }`}
                data-title="Payment"
              >
                <div className="title" id="payment">
                  Payment
                </div>
                <PaymentStep store={this.store} />
              </div>
            </React.Fragment>
          )}

          {page.completeStep === page.stepIndex && (
            <div
              className={`step-content-inner ${"flex animate__animated animate__fadeIn"}`}
              data-title="Complete"
            >
              <CompleteStep store={this.store} />
            </div>
          )}
        </div>
        <div
          className="footer"
          style={{
            display: page.completeStep === page.stepIndex ? "none" : "flex",
          }}
        >
          {!this.store.page.completed && (
            <React.Fragment>
              <div
                className="social"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: 150,
                }}
              >
                <div>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${this.store.location && this.store.location.phone_whatsapp
                      }`}
                    target="_blank"
                  >
                    <ImWhatsapp color="#000" fontSize="20" />
                  </a>
                </div>
                <div>
                  <a
                    href={`mailto:${this.store.location && this.store.location.email
                      }`}
                    target="_blank"
                  >
                    <AiOutlineMail color="#000" fontSize="24" />
                  </a>
                </div>
                <div>
                  <a
                    href={`tel:${this.store.location && this.store.location.phone
                      }`}
                    target="_blank"
                  >
                    <ImPhone color="#000" fontSize="20" />
                  </a>
                </div>
              </div>

              <div className="price-summary">
                {!this.store.location.hide_price && (
                  <React.Fragment>
                    <div className="payment-options">
                      <span>
                        PURCHASE <strong>PRICE: </strong>
                      </span>
                    </div>
                    <div className="price-display">
                      <CountUp
                        className="price-text orbitron"
                        separator=","
                        prefix={this.store.selectedCurrency.toUpperCase() + " "}
                        duration={1}
                        start={this.store.lastPrice}
                        end={this.store.summary.purchase_price}
                      />
                    </div>
                    <div style={{ fontSize: 12, fontWeight: 'bold' }}>
                      The factory prices are in SEK and are adjusted to EURO.
                    </div>
                  </React.Fragment>
                )}
                <div className="buttons_mobile">
                  <div className="modelSelector__cleanbutton blue">
                    <a
                      onClick={() => {
                        this.store.onStepSelected({}, this.store.page.pdfStep);

                        setTimeout(() => {
                          SmoothScrolling.scrollTo("step-summary");
                        }, 400);
                      }}
                    >
                      SEND ME PDF
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="estimated"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 12,
                  justifyContent: "flex-end",
                }}
              >
                <SpecPopup model={this.store.data} />
              </div>
              <div className="delivery">
                <div className="button-wrapper">
                  {page.stepIndex == 0 && (
                    <div
                      className="modelSelector__cleanbutton red"
                      style={{ marginRight: 15 }}
                    >
                      <a href={`/`}>
                        <Translation lang={this.store.page.lang} t="prev" />
                      </a>
                    </div>
                  )}

                  {page.stepIndex > 0 &&
                    (page.stepIndex == page.paymentStep ? (
                      <div
                        className="modelSelector__cleanbutton blue"
                        style={{ marginRight: 15 }}
                      >
                        <a
                          onClick={() =>
                            this.store.onStepSelected({}, page.summaryStep)
                          }
                        >
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ) : (
                      <div
                        className="modelSelector__cleanbutton red"
                        style={{ marginRight: 15 }}
                      >
                        <a onClick={this.store.prevStep}>
                          <Translation lang={this.store.page.lang} t="prev" />
                        </a>
                      </div>
                    ))}

                  {page.stepIndex == page.summaryStep && (
                    <React.Fragment>
                      <div className="modelSelector__cleanbutton blue">
                        <a
                          onClick={() =>
                            this.store.onStepSelected(
                              {},
                              this.store.page.pdfStep
                            )
                          }
                        >
                          <Translation
                            t="button_pdf"
                            lang={this.store.page.lang}
                          />
                        </a>
                      </div>
                      {appConfig.locale.has_payment && (
                        <div
                          className="modelSelector__cleanbutton blue"
                          style={{ marginLeft: 15 }}
                        >
                          <a
                            onClick={() =>
                              this.store.onStepSelected(
                                {},
                                this.store.page.paymentStep
                              )
                            }
                          >
                            RESERVE NOW
                          </a>
                        </div>
                      )}
                    </React.Fragment>
                  )}

                  {page.stepIndex == page.pdfStep && (
                    <div className="modelSelector__cleanbutton blue">
                      <a
                        onClick={(e) => {
                          var form = document.getElementById("pdf_form");

                          if (form.reportValidity()) {
                            this.store.onPdfSubmit(e);
                          }
                        }}
                      >
                        <Translation
                          t="button_pdf"
                          lang={this.store.page.lang}
                        />
                      </a>
                    </div>
                  )}

                  {page.stepIndex < page.summaryStep &&
                    (this.store.checkNext ? (
                      this.store.page.nextEnabled ? (
                        <div className="modelSelector__cleanbutton blue">
                          <a onClick={this.store.nextStep}>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      ) : (
                        <div className="modelSelector__cleanbutton blue disabled">
                          <a>
                            <Translation lang={this.store.page.lang} t="next" />
                          </a>
                        </div>
                      )
                    ) : (
                      <div className="modelSelector__cleanbutton blue disabled">
                        <a title="You must select at least one option">
                          <Translation lang={this.store.page.lang} t="next" />
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>

        <DependencyPopup />
        <DependantPopup />
      </div>
    );
  }
}

export default App;
