import React from "react";
import { appConfig } from "../config";
import { convertCurrencyLocation, sortingComparer } from "../helpers";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import CountUp from "react-countup";

@inject("AppStore")
@observer
class OptionWithImage extends React.Component {
  store = this.props.AppStore;

  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${type === "singleImage"
          ? "option-radio-image"
          : "option-checkbox-image"
          } ${option.selected ? "option-selected" : ""} ${option.image_top ? "image-top" : ""
          }`}
        style={{ display: option.hidden ? "none" : "" }}
        onClick={() => store.selectOption(option, group)}
      >
        {option.picture && (
          <div className="option-image">
            <img
              alt=""
              src={`${appConfig.api}${option.picture.url}`}
              className="animate__animated animate__fadeIn"
            />
          </div>
        )}
        <div className="option-content">
          <div className="option-title">{option[store.langify("title")]}</div>
          {option.description && (
            <div className="option-description">
              <pre>{option[store.langify("description")]}</pre>
            </div>
          )}
          {!this.store.location.hide_price && (
            <div className="option-price">
              {convertCurrencyLocation(option.price, this.store.location, this.store.selectedCurrency)}
            </div>
          )}
        </div>
        <div className={`check-indicator ${option.selected ? "selected" : ""}`}>
          <div className="check-indicator-inside"></div>
        </div>
      </div>
    );
  }
}

@inject("AppStore")
@observer
class OptionWithoutImage extends React.Component {
  store = this.props.AppStore;

  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${type === "single" ? "option-radio" : "option-checkbox"
          } ${option.selected ? "option-selected" : ""}`}
        style={{ display: option.hidden ? "none" : "flex" }}
        onClick={() => store.selectOption(option, group)}
      >
        {option.picture && (
          <div
            className="option-image"
            style={{ borderColor: option.selected ? store.data.color : "#fff" }}
            onClick={() => store.selectOption(option, group)}
          >
            <img
              alt=""
              src={`${appConfig.api}${option.picture.url}`}
              className="animate__animated animate__fadeIn"
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <div className="option-title">{option.description}</div>
          <div className="option-radio-description">{option.title}</div>
          {!this.store.location.hide_price && (
            <div className="option-price">
              {convertCurrencyLocation(option.price, this.store.location, this.store.selectedCurreny)}
            </div>
          )}
        </div>
        <div className={`check-indicator ${option.selected && "selected"}`}>
          <div className="check-indicator-inside"></div>
        </div>
      </div>
    );
  }
}

@observer
class OptionPicker extends React.Component {
  render() {
    const { option, group, store } = this.props;

    return (
      <div
        className={`option option-picker ${option.selected ? "option-selected" : ""
          }`}
      >
        {option.picture && (
          <div
            className="option-image"
            style={{ borderColor: option.selected ? store.data.color : "#fff" }}
            onClick={() => store.selectOption(option, group)}
          >
            <img
              alt=""
              src={`${appConfig.api}${option.picture.url}`}
              className="animate__animated animate__fadeIn"
            />
          </div>
        )}

        <div className="option-title">{option.title}</div>
      </div>
    );
  }
}

@inject("AppStore")
@observer
class OptionAdditional extends React.Component {
  store = this.props.AppStore;

  render() {
    const { option, group, store } = this.props;

    return (
      <div
        className={`option option-additional ${option.selected ? "option-selected" : ""
          }`}
        style={{ display: option.hidden ? "none" : "" }}
      >
        <div className="option-additional-title">
          SPECIFY {group.title} HERE
        </div>
        <div className="option-content">
          <div className="option-title">
            <input
              type="text"
              className="option-additional-text"
              value={option.specified_value}
              placeholder={`ENTER YOUR DESIRED ${group.title}`}
              onClick={(e) => {
                if (!option.selected) {
                  store.selectOption(option, group);
                }
              }}
              onChange={(e) => {
                option.specified_value = e.target.value;
                option.title = `${e.target.value}`;
              }}
            />
          </div>
          {!this.store.location.hide_price && (
            <div
              className="option-price"
              onClick={() => store.selectOption(option, group)}
            >
              {convertCurrencyLocation(option.price, this.store.location, this.store.selectedCurrency)}
              <span
                className={`option-selector ${option.selected && "selected"}`}
              >
                <span
                  className="tick"
                  style={{ background: store.data.color }}
                ></span>
              </span>
            </div>
          )}
        </div>

        {option.description && (
          <div className="option-description">
            <pre>{option[store.langify("description")]}</pre>
          </div>
        )}
      </div>
    );
  }
}

@inject("AppStore")
@observer
class ImageStep extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    console.log("image step");
  }

  checkRelated = (step, group) => {
    const other_options = step.option_groups.flatMap((x) =>
      toJS(x.options).filter((o) => o.option_group !== group.id)
    );

    const found = other_options.filter(
      (x) => x.related && x.related.option_group === group.id
    );

    if (found.length > 0 && !found.some((x) => x.selected)) return false;

    return true;
  };

  render() {
    const { store, step, data } = this.props;

    return (
      <div className="step-image-content">
        <div
          className="image"
          style={{
            backgroundImage: data.picture
              ? `url(${appConfig.api}${data.picture.url})`
              : "url(https://via.placeholder.com/1920x1080)",
          }}
        >
          <div className="engine_info">
            <div className="engine_info__item">
              <span
                dangerouslySetInnerHTML={{ __html: store.data.name }}
              ></span>
            </div>

            <div className="engine_info__item">
              <div className="engine_info__title">Speed</div>
              <div className="engine_info__value">
                <CountUp
                  className="price-text orbitron"
                  suffix={` ${store.page.speed.unit}`}
                  duration={1}
                  start={0}
                  end={store.page.speed.value}
                />
              </div>
            </div>
            <div className="engine_info__item" style={{ border: "none" }}>
              <div className="engine_info__title">Power</div>
              <div className="engine_info__value">
                <CountUp
                  className="price-text orbitron"
                  suffix={` ${store.page.power.unit}`}
                  duration={1}
                  start={0}
                  end={store.page.power.value}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="options-wrapper"
          onClick={(e) => {
            var element = e.currentTarget;
            if (
              element.scrollHeight - element.scrollTop ===
              element.clientHeight
            ) {
              store.page.nextEnabled = true;
            }
          }}
          onScroll={(e) => {
            var element = e.currentTarget;
            if (
              element.scrollHeight - element.scrollTop ===
              element.clientHeight
            ) {
              store.page.nextEnabled = true;
            }
          }}
        >
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: data.name }}
          ></div>

          <div className="option-groups">
            {step.option_groups.sort(sortingComparer).map(
              (group, i) =>
                this.checkRelated(step, group) && (
                  <React.Fragment key={i}>
                    {group.group_title && group.show_group_title && (
                      <div className="option-group-subtitle">
                        {group.group_title}
                      </div>
                    )}

                    <div className={`option-group ${group.type}`}>
                      {!group.hide_title && (
                        <div
                          className="option-group-title"
                          style={{ display: "flex" }}
                        >
                          <div className="selectedModel__subtitle">
                            <div
                              className="selectedModel__subtitle--top uc"
                              style={{ borderColor: `${store.data.color}` }}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {group.title}
                              </span>{" "}
                              SELECTION
                            </div>
                            <div className="selectedModel__subtitle--bottom">
                              {group.required && "MANDATORY SELECTION"}
                            </div>
                          </div>
                          <div className="brand-logo">
                            {group.brand && (
                              <img
                                alt=""
                                src={`${appConfig.api}${group.brand.url}`}
                              />
                            )}
                          </div>
                        </div>
                      )}

                      {group.description && (
                        <div
                          className="option-maindescription"
                          style={{ fontSize: 12, padding: 15 }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: group.description,
                            }}
                          ></div>
                        </div>
                      )}

                      <div
                        className={`options`}
                        style={{
                          gridTemplateColumns: group.grid_size
                            ? `repeat(${group.grid_size}, 1fr)`
                            : "",
                        }}
                      >
                        {group.options.map((option, j) => {
                          if (option.additional) {
                            return (
                              <OptionAdditional
                                key={j}
                                type={group.type}
                                group={group}
                                option={option}
                                store={store}
                              />
                            );
                          } else if (
                            group.type === "singleImage" ||
                            group.type === "multiImage"
                          ) {
                            return (
                              <OptionWithImage
                                key={j}
                                type={group.type}
                                group={group}
                                option={option}
                                store={store}
                              />
                            );
                          } else if (group.type === "picker") {
                            return (
                              <React.Fragment key={j}>
                                <OptionPicker
                                  type={group.type}
                                  group={group}
                                  option={option}
                                  store={store}
                                />
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <OptionWithoutImage
                                key={j}
                                type={group.type}
                                group={group}
                                option={option}
                                store={store}
                              />
                            );
                          }
                        })}
                      </div>
                    </div>
                  </React.Fragment>
                )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ImageStep;
