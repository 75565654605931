import moment from "moment";
import { toJS } from 'mobx'

export const getPrice = (price, location, selectedCurrency) => {
  let priceValue = 0;

  priceValue = price[location.currency];

  if (selectedCurrency != location.currency) {
    let locationPrice = priceValue;
    priceValue = price[selectedCurrency];

    if (!priceValue) priceValue = locationPrice * location.rates[selectedCurrency];
  }

  return priceValue;
};


export const convertCurrencyLocation = (price, location, selectedCurrency) => {
  let priceValue = 0;

  if (typeof price === "object") {
    if (!price) return "Included";

    priceValue = price[location.currency];

    if (priceValue === -1) return "TBA";
    if (priceValue === -2) return "Per Design";
    if (priceValue === -3) return "Select Lounge Layout";
    if (priceValue === -4) return "Standard";
    if (priceValue === -5) return "Select";
    if (!priceValue) return "Included";
  }

  if (selectedCurrency != location.currency) {
    let locationPrice = priceValue;
    priceValue = price[selectedCurrency];

    if (!priceValue) priceValue = locationPrice * location.rates[selectedCurrency];
  }

  const currency = new Intl.NumberFormat(location.culture, {
    style: "currency",
    currency: selectedCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(priceValue);

  return currency;
};

export const convertCurrencySummaryLocation = (price, location, selectedCurrency, hasPlus) => {
  let priceValue = 0;

  if (typeof price === "object") {
    if (!price) return "Included";

    priceValue = price[location.currency];

    if (priceValue === -1) return "TBA";
    if (priceValue === -2) return "Per Design";
    if (priceValue === -3) return "Select Lounge Layout";
    if (priceValue === -4) return "Standard";
    if (priceValue === -5) return "Select";
    if (!priceValue) return "Included";
  }
  else {
    return new Intl.NumberFormat(location.culture, {
      style: "currency",
      currency: selectedCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  }

  if (selectedCurrency != location.currency) {
    let locationPrice = priceValue;
    priceValue = price[selectedCurrency];

    if (!priceValue) priceValue = locationPrice * location.rates[selectedCurrency];
  }

  const currency = new Intl.NumberFormat(location.culture, {
    style: "currency",
    currency: selectedCurrency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(priceValue);

  return hasPlus ? `+ ${currency}` : currency;
};

export const datePicker = () => {
  var dates = [];

  for (var i = 1; i < 24; i++) {
    var date = moment().date(1).add(i, "months");
    var text = date.format("MMMM, YYYY");
    var item = { value: date.format("MM/DD/YYYY"), text };

    // if (i == 0) {
    //   item.text = item.text + " - Sold out";
    //   item.disabled = true;
    // }

    // if (i == 2 || i == 3) {
    //   item.text = item.text + " - 1 Available";
    // }

    dates.push(item);
  }

  return dates;
};

export const sortingComparer = (a, b) => {
  if (a.sorting > b.sorting) return -1;
  if (a.sorting < b.sorting) return 1;
  return 0;
};
