import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import Select from "./Select";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "mobx-react";
import { Store } from "./store";

const appStore = new Store();

ReactDOM.render(
  <Provider AppStore={appStore}>
    <Router basename={"/"}>
      <Route path="/build/:slug/:number" exact component={App} />
      <Route path="/build/:slug" exact component={App} />
      <Route path="/" exact component={Select} />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
